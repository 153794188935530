@import url(https://fonts.googleapis.com/css2?family=Caveat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;200;300;400;500;600;800;900&display=swap);
:root {
  --Poppins: "Poppins";
  --Comfortaa: "Comfortaa";
  --Caveat: "Caveat";
  --h1: 40px;
  --h2: 26px;
  --h3: 20px;
  --Color-1: rgba(88, 10, 177, 0.953);
  --Color-2: rgba(246, 142, 6, 0.994);
  --Color-3: rgba(88, 10, 177, 0.398);
  --Color-4: rgba(246, 198, 6, 0.83);
  --Color-5: rgba(2, 200, 235, 0.83);
  --White: #fff;
}


body {
  font-family: 'Montserrat', sans-serif;
}

* {
  margin: 0;
padding: 0;
 box-sizing: border-box;
}




.accordion {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-bottom: 4%;
    padding-top: 1%;
    z-index: 0;
    transition: all .3s linear;
   
}

h2{
    color: var(--Color-1);
}

/* @media (max-width: 900px) {
    padding-bottom: 7%;
    padding-top: 4%;
  } */


.accordion .container {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    padding: 10px;
    max-width: 95%;
}

.accordion .wrapper {
    overflow: hidden;
    position: relative;
}

.wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 95%;
    margin: auto;
    height: auto;
    cursor: pointer;
    padding: 20px;
}

h1 {
    font-size: 13pt;
    

}

.dropdown {
    padding: 0px;
    color: rgba(179, 113, 33, 0.575);
    max-width: 95%;
    height: 0;
    overflow: hidden;
    transition: all .3s linear;
}


.dropdown.expand {
    height: auto;
    padding: 10px 25px;

}


p {
    margin-top: 10px;
}

@media (max-width: 900px) {
    .accordion .container {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        padding: 20px;
        width: 100%;
    }
    h2{
        font-size: 18px;
    }
    .accordion .container {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        padding: 0;
        max-width: 95%;
    }

    .accordion .wrapper {
        overflow: hidden;
        position: relative;
        width: 96%;
    }

    .wrap {
        /* color: var(--Color-1); */
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 98%;
        margin: auto;
        height: auto;
        cursor: pointer;
        padding: 20px;
    }

    h1 {
        font-size: 15pt;
    }

    .dropdown {
        padding: 0px;
        color: rgba(179, 113, 33, 0.575);
        max-width: 95%;
        height: 0;
        overflow: hidden;
        transition: all .3s linear;
    }


    .dropdown.expand {
        height: auto;
        padding: 10px 25px;

    }


    p {
        margin-top: 10px;
    }

    h1 {
        font-size: 12pt;
    }
}
